<template>
  <div class="justify-content-right">
    <div right class="logo" href="https://www.unlp.edu.ar" target="_blank">
      <span class="logo3-img"></span>
    </div>


  </div>
</template>

<script lang="ts" src="./jhi-footer.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ==========================================================================
    Navbar
    ========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #8A2BE2;
}

.jh-navbar {
  background-color: #fafafa;
  padding: 0.2em 1em;
}

.jh-navbar .profile-image {
  margin: -10px 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.jh-navbar .dropdown-item.active,
.jh-navbar .dropdown-item.active:focus,
.jh-navbar .dropdown-item.active:hover {
  background-color: #fafafa;
}

.jh-navbar .dropdown-toggle::after {
  margin-right: 0.15em;
}

.jh-navbar ul.navbar-nav {
  padding: 0.5em;
}

.jh-navbar .navbar-nav .nav-item {
  margin-right: 1.5rem;
}

.jh-navbar a.nav-link,
.jh-navbar .no-bold {
  font-weight: 400;
}

.jh-navbar .jh-navbar-toggler {
  color: #8A2BE2;
  font-size: 1.5em;
  padding: 10px;
}

.jh-navbar .jh-navbar-toggler:hover {
  color: #fff;
}

/* @media screen and (min-width: 768px) {
  .jh-navbar-toggler {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
} */

.navbar-title {
  display: inline-block;
  vertical-align: middle;
  color: white;
}

/* ==========================================================================
    Logo styles
    ========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px;

  justify-content: flex-end;
}

.logo .logo-img {
  height: 90px;
  display: inline-block;
  vertical-align: middle;
  width: 140px;
}

.logo .logo3-img {
  height: 90px;
  /* display: inline-block; */
  display: flex;
  justify-content: flex-end;
  /*  vertical-align: middle; */
  width: 200px;
}

.logo-img {
  height: 100%;
  background: url('../../../content/images/logo_coop_bba_instagram.jpg') no-repeat center center;
  background-size: contain;
  width: 100%;
  filter: drop-shadow(0 0 0.05rem white);
}

.logo3-img {

  background: url('../../../content/images/escuelaPublica.jpg') no-repeat center center;
  background-size: contain;

  /*  filter: drop-shadow(0 0 0.05rem rgb(0, 0, 0)); */
}
</style>
